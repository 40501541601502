import(/* webpackMode: "eager", webpackExports: ["ReactQueryClientProvider"] */ "/app/apps/cannabis-app/components/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/apps/cannabis-app/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/i18n/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/ui/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/globals.css");
